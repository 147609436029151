import { useEffect, useState } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import IBookingCenterOptionResponse, { ResponseBookingCenterOption } from "../../Types/IBookingCenterOptionResponse";
import ICenter from "../../Types/ICenter";

export interface UseGetBookingCenterOptionsProps {
  /** The postal code to use as the origin. */
  postalCode?: string;
  /** The requested Center Id. */
  centerId?: string;
}

/** A hook that obtains the list of centers in which the application can book appointments if a calendar can be resolved. */
const UseGetBookingCenterOptions: Hook<ICenter[], boolean, UseGetBookingCenterOptionsProps> = ({
  postalCode,
  centerId
}) => {
  const { res, err, loading, setReq } = useObx<IBookingCenterOptionResponse[]>("Get Bookable Centers");

  const [result, setResult] = useState<Nullable<ICenter[]>>(() => {
    setReq(r => r && { ...r, url: `${ApiRequestPaths.getCentersV3()}?postalCode=${postalCode}&centerId=${centerId}` });
    return null;
  });

  useEffect(() => {
    res && setResult(res.payload.map(c => new ResponseBookingCenterOption(c)));
  }, [res]);

  return { result, error: Boolean(err), isLoading: loading };
};

export default UseGetBookingCenterOptions;
