/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { IControl, IControlledMutator, Nullable, TriggeredHook } from "sonobello.utilities.react";
import { useSignalR as useSignalRHook } from "sonobello.utilities.react.signalr";

import { ISomethingWentWrongRouter } from "../../Routing/Types/IRouter";
import { TopicPayload, TopicSource } from "../Types/TopicSource";

export interface IUseGetConnectionInfoProps {
  /** The maximum number of times that a failed connection should be reattempted before being reported as an error. */
  maxConnectionAttempts: number;
}

/** The required connection configuration to establish a {@link  HubConnection} */
export interface ISignalRConnectionConfiguration {
  /** The url to be used to request a connection. */
  readonly url: string;
  /** The auth token which will authorize the the connection request. */
  readonly accessToken: string;
}

export interface ISignalRListenerProps<TTopicCustom, TTopicPayload>
  extends IControl<TopicPayload<TTopicCustom, TTopicPayload>[]> {
  /** Callback to subscribe to topics from the SignalR webhook. */
  subscribe: Dispatch<TopicSource<TTopicCustom>[]>;
  /** Callback to unsubscribe from topics from the SignalR webhook. */
  unsubscribe: Dispatch<string>;
}

export type ISignalRConnectionProps = IControlledMutator<Nullable<ISignalRConnectionConfiguration>> &
  IUseGetConnectionInfoProps;

export interface ISignalRConnectionConfig {
  /** A router which can send the user to the 'something went wrong' view. */
  router: ISomethingWentWrongRouter;
  /** A hook which obtains the latest state of the SignalR connection configuration. */
  useGetConnectionInfo: TriggeredHook<ISignalRConnectionConfiguration, undefined, boolean, IUseGetConnectionInfoProps>;
  /** A hook which connects to the SignalR hub with the given connection configuration  */
  useSignalR: typeof useSignalRHook;
  /** The component which is controlled by the SignalR messages. */
  SignalRListener: React.FC<ISignalRListenerProps<any, any>>;
}

/** Maintains the connection state to the SignalR webhook.
 * @remarks We are allowing `any` types in this file so that the output type can be inherited without issues in
 * composition.
 */
const SignalRConnection: React.FC<ISignalRConnectionProps & ISignalRConnectionConfig> = ({
  router,
  value,
  onChange,
  useGetConnectionInfo,
  useSignalR,
  SignalRListener,
  ...rest
}) => {
  const [topicPayloads, setTopicPayloads] = useState<TopicPayload<any, any>[]>([]);
  const { result, error, execute } = useGetConnectionInfo(rest);
  const { signalRPayloads, subscribe, unsubscribe } = useSignalR(value || undefined, { onUnauthorized: execute });
  const handleSubscribe = useCallback((topicSources: TopicSource<any>[]) => {
    setTopicPayloads(payloads => {
      const newTopicSubscriptions = topicSources.filter(ts => !payloads.some(p => p.topic === ts.topic));
      if (!newTopicSubscriptions.length) return payloads;

      subscribe(newTopicSubscriptions.map(p => p.topic));
      return [...payloads, ...newTopicSubscriptions.map(t => t.GetPayload(null))];
    });
  }, []);
  const handleUnsubscribe = useCallback((unsubscribeTopic: string) => {
    unsubscribe(unsubscribeTopic);
    setTopicPayloads(state => state.filter(t => t.topic !== unsubscribeTopic));
  }, []);

  useEffect(() => {
    setTopicPayloads(topicStates => {
      topicStates.forEach(ts => {
        ts.payload = signalRPayloads[ts.topic];
      });
      return [...topicStates];
    });
  }, [signalRPayloads]);

  useEffect(() => {
    if (!value) execute();
  }, [value]);

  useEffect(() => {
    if (result) onChange(result);
  }, [result]);

  useEffect(() => {
    if (error) router.goToSomethingWentWrongView();
  }, [error]);
  return value && <SignalRListener value={topicPayloads} unsubscribe={handleUnsubscribe} subscribe={handleSubscribe} />;
};

export default SignalRConnection;
