import { IANAZone } from "luxon";

import { Customer } from "../../../../dtos/Customer";
import { Token } from "../../../../dtos/Token";
import { TimeZoneStandardName, TzTimezoneToZoneIdMap } from "../../../Types/IBookingCenterOptionResponse";
import { DefaultCenter } from "../../../Types/ISession";

/** The object payload used to make a POST token request. */
export class TokenRequest {
  /** The key of the obx session from the unique OBX uri. */
  obxLinkValue: string;

  constructor(sessionKey: string) {
    this.obxLinkValue = sessionKey;
  }
}

/** The object payload on a POST token request response. */
export interface TokenResponse {
  /** The customer associated with the token request. */
  customer: Omit<Customer, "height" | "weight" | "dateOfBirth">;
  /** The center associated with the token request. */
  center: {
    /** The unique identifier of the center. */
    id: string;
    /** The plain text name of the center. */
    name: string;
    /** The plain text information of the center's address. */
    address: string;
    /** The phone number used to contact the center regarding appointment details or concerns. */
    phoneNumber: string;
    /** The center's time zone. */
    timeZone: {
      /** The name of the center's time zone in the 'TZ' format. */
      currentName: TimeZoneStandardName;
    };
  };
  /** The lead id associated with the token request. */
  leadId: string;
  /** The flow id associated with the token request. */
  flow: {
    /** The id of the flow associated with the token request. */
    id: string;
    /** The flow name associated with the token request. */
    name: string;
  };
  /** The token authorizing future requests for this session. */
  token: Token;
}

/** Builds a {@link DefaultCenter} from a {@link IBookingCenterOptionResponse}. */
export class TokenDefaultCenter extends DefaultCenter {
  constructor(tokenCenter: TokenResponse["center"]) {
    const ianaZone = new IANAZone(TzTimezoneToZoneIdMap[tokenCenter.timeZone.currentName]);
    if (!ianaZone.isValid)
      throw `Failed to convert the 'TZ' time zone name '${tokenCenter.timeZone.currentName}' to an IANA time zone.`;
    super(tokenCenter.id, tokenCenter.name, tokenCenter.address, tokenCenter.phoneNumber, ianaZone);
  }
}
