import { useCallback } from "react";

import { FlowStepProgress } from "../../../dtos/FlowStepProgress";
import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { IAppErrorBoundaryConfig } from "../Components/AppErrorBoundary";

/** When called, sends a flow step progress dispatch for 'something went wrong' and flow is ended 'true'. */
const useReportSomethingWentWrong: IAppErrorBoundaryConfig["ReportSomethingWentWrong"] = () => {
  const { err, loading, setReq } = useObx<unknown, FlowStepProgress>("ReportSomethingWentWrong", { method: "post" });
  const execute = useCallback(
    (props: Parameters<ReturnType<IAppErrorBoundaryConfig["ReportSomethingWentWrong"]>["execute"]>[0]) =>
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postStepProgress(props.leadId, props.flowId),
            payload: { name: "somethingWentWrong", isEnded: true },
            config: { headers: { authorization: `Bearer ${props.bearerToken}` } }
          }
      ),
    []
  );
  return { result: null, error: Boolean(err), execute, isLoading: loading };
};

export default useReportSomethingWentWrong;
