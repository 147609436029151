import React, { ComponentProps } from "react";
import { useLocation } from "react-router-dom";
import { useSignalR } from "sonobello.utilities.react.signalr";

import AppContextValueProvider from "../../App/Compositions/AppContextValueProvider";
import IPathObserver from "../../Routing/Types/IPathObserver";
import CalendarSignalRListener, {
  ICalendarSignalRListenerConfig
} from "../../SignalR/Components/CalendarSignalRListener";
import { ISignalRConnectionConfig } from "../../SignalR/Components/SignalRConnection";
import CachedSignalRConnection from "../../SignalR/Compositions/CachedSignalRConnection";
import usePostSignalRNegotiate from "../../SignalR/Hooks/usePostSignalRNegotiate";
import CalendarsManager, { ICalendarsManagerConfig, ICalendarsManagerProps } from "../Components/CalendarManager";
import CenterCalendarWorker from "../Components/CenterCalendarWorker";
import HybridCalendarWorker, { IHybridCalendarWorkerConfig } from "../Components/HybridCalendarWorker";
import IdentityCalendarWorker from "../Components/IdentityCalendarWorker";
import useGetLegacyCalendar from "../Hooks/UseGetLegacyCalendar";
import useGetLegacyCenterCalendar from "../Hooks/UseGetLegacyCenterCalendar";
import AppContextOpsCalendarController from "./AppContextOpsCalendarController";
import AppContextUseGetBookingCenterOptions from "./AppContextUseGetBookingCenterOptions";
import AppContextUseGetOpsCenterCalendar from "./AppContextUseGetOpsCenterCalendar";

const AppContextCalendarManager = AppContextValueProvider<
  ICalendarsManagerProps,
  ComponentProps<typeof CalendarsManager>
>({
  Component: CalendarsManager,
  map: ({ customerCState, defaultCenter, sessionKeyCState, router, setBookingController }) => {
    return {
      businessUnitId: customerCState.opportunity.campaign.businessUnit.id,
      customerFirstName: customerCState.firstName,
      defaultCenter,
      router,
      sessionKey: sessionKeyCState,
      setBookingController
    };
  }
});

const OpsWorker: IHybridCalendarWorkerConfig["OpsWorker"] = props => {
  const location = useLocation();
  return (
    <AppContextOpsCalendarController pathName={location.pathname}>
      <CenterCalendarWorker {...props} useGetCenterCalendar={AppContextUseGetOpsCenterCalendar} />
    </AppContextOpsCalendarController>
  );
};
const LegacyWorker: IHybridCalendarWorkerConfig["LegacyWorker"] = props => (
  <IdentityCalendarWorker
    {...props}
    useGetCalendar={useGetLegacyCalendar}
    useGetCenterCalendar={useGetLegacyCenterCalendar}
  />
);

const HybridWorker: ICalendarsManagerConfig["Worker"] = props => (
  <HybridCalendarWorker {...props} OpsWorker={OpsWorker} LegacyWorker={LegacyWorker} />
);

const Manager: ICalendarSignalRListenerConfig["CalendarManager"] = props => (
  <AppContextCalendarManager {...props} useGetCenters={AppContextUseGetBookingCenterOptions} Worker={HybridWorker} />
);

const SignalRListener: ISignalRConnectionConfig["SignalRListener"] = props => (
  <CalendarSignalRListener {...props} CalendarManager={Manager} />
);

/** A fully configured calendar manager which uses OPS workers for OPS configured centers, and legacy workers for
 * all other centers.
 */
const OpsSupportedCalendarManager: React.FC<
  Pick<ISignalRConnectionConfig, "router"> & Pick<ICalendarsManagerProps, "router"> & IPathObserver
> = ({ router }) => {
  return (
    <CachedSignalRConnection
      router={router}
      useGetConnectionInfo={usePostSignalRNegotiate}
      useSignalR={useSignalR}
      maxConnectionAttempts={3}
      SignalRListener={SignalRListener}
    />
  );
};

export default OpsSupportedCalendarManager;
