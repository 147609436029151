import { useContext } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import AppContext from "../../../AppContext";
import { CalendarFailure } from "../Components/CalendarManager";
import { ICalendarHookProps } from "../Components/IdentityCalendarWorker";
import { useGetOpsCenterCalendar } from "../Hooks/UseGetOpsCenterCalendar";
import ICalendar from "../Types/ICalendar";

const AppContextUseGetOpsCenterCalendar: TriggeredHook<
  ICalendar,
  undefined,
  CalendarFailure,
  ICalendarHookProps
> = props => {
  const { customerCState, leadIdCState } = useContext(AppContext);
  return useGetOpsCenterCalendar({ ...props, leadId: leadIdCState, customerId: customerCState.id });
};

export default AppContextUseGetOpsCenterCalendar;
