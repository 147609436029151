import React, { Dispatch, SetStateAction, useEffect } from "react";
import { IDisable, Nullable, TriggeredHook } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import { Customer, EnhancedCustomer } from "../../../../dtos/Customer";
import { DisqualifyingReason, DisqualifyingReasonsType } from "../../../../dtos/DisqualifyingReason";
import { LeadResult } from "../../../../dtos/LeadResult";
import { IFlowStepProps } from "../../../../types/Flow";
import { MedicalForm } from "../../../../types/MedicalForm";
import { Step } from "../../../../types/Step";
import IBookingController from "../../../Calendar/Types/IBookingController";
import ICenter from "../../../Types/ICenter";
import StepWrapper, { IStepWrapperButtonProps } from "../../Components/StepWrapper";

export interface IUseCreateNonCandidateLeadResultTrigger {
  /** The id of the customer. */
  customerId: string;
  /** The id of the customer's opportunity id. */
  customerOpportunityId: string;
  /** The reason why the customer is disqualified. */
  disqualificationReason: DisqualifyingReasonsType;
  /** The list of possible disqualification reasons. */
  disqualifyingReasons: DisqualifyingReason[];
}

export interface IQualifyStepWrapperProps {
  /** The current state of the session's customer. */
  customer: EnhancedCustomer;
  /** The center that was originally assigned to the session. */
  defaultCenter: ICenter;
  /** The current state of the bookable centers for the session. */
  bookingController: Nullable<Pick<IBookingController, "getTrackedCenters">>;
  /** The list of available disqualifying reasons, which is null until loaded from the cache or the server. */
  disqualifyingReasons: Nullable<DisqualifyingReason[]>;
  /** The current state of the medical form for the session. */
  medicalForm: MedicalForm;
  /** The id of the lead associated with the session. */
  leadId: string;
  /** The function which updates the session's customer state. */
  setCustomer: Dispatch<SetStateAction<EnhancedCustomer>>;
  /** The function which updates the session's lead result state. */
  setLeadResult: Dispatch<LeadResult>;
}

export interface IQualifyStepWrapperConfig {
  /** The hook which creates a non-candidate lead result for the session. */
  UseCreateNonCandidateLeadResult: TriggeredHook<
    LeadResult,
    IUseCreateNonCandidateLeadResultTrigger,
    boolean,
    { leadId: string }
  >;
  /** The hook which updates the persisted customer state. */
  UseUpdateCustomer: TriggeredHook<true, Customer & Parameters<typeof Customer.getBmi>[0], boolean, { leadId: string }>;
  /** The component which should be rendered within this wrapper. */
  QualifyStep: React.FC<IDisable>;
}

const QualifyStepWrapper: React.FC<
  IQualifyStepWrapperConfig & IQualifyStepWrapperProps & IStepWrapperButtonProps & IFlowStepProps
> = ({
  customer,
  defaultCenter,
  bookingController,
  disqualifyingReasons,
  medicalForm,
  leadId,
  setCustomer,
  setLeadResult,
  UseCreateNonCandidateLeadResult,
  UseUpdateCustomer,
  QualifyStep,
  setStep,
  ...props
}) => {
  const {
    result: updateCustomerResult,
    isLoading: updateCustomerIsLoading,
    execute: executeUpdateCustomer,
    error: updateCustomerError
  } = UseUpdateCustomer({ leadId });
  const {
    result: createLeadResultResult,
    isLoading: createLeadResultIsLoading,
    execute: executeCreateLeadResult,
    error: createLeadResultError
  } = UseCreateNonCandidateLeadResult({ leadId });

  const onClickNext = () => {
    setCustomer(c => {
      if (!c.height || !c.weight || !c.dateOfBirth || !disqualifyingReasons)
        throw new Error("Customer must have height/weight/dateOfBirth to continue.");
      const updatedCustomer = {
        ...(c as EnhancedCustomer & Required<Pick<Customer, "height" | "weight" | "dateOfBirth">>),
        distanceToCenter:
          c.distanceToCenter ??
          bookingController?.getTrackedCenters().find(c => c.id === defaultCenter.id)?.distance ??
          EnvironmentConfiguration.optimizedPatientScheduler.fallbackCustomerDistanceToCenter
      };
      EnhancedCustomer.createOrUpdateNote(updatedCustomer, {
        customer: updatedCustomer,
        center: defaultCenter,
        disqualifyingReasons,
        medicalForm,
        service: null
      });
      const disqualificationReason = Customer.getDisqualifyingReason(updatedCustomer);
      if (disqualificationReason)
        executeCreateLeadResult({
          customerId: customer.id,
          customerOpportunityId: customer.opportunity.id,
          disqualificationReason,
          disqualifyingReasons
        });
      executeUpdateCustomer(updatedCustomer);
      return updatedCustomer;
    });
  };

  useEffect(() => {
    if (updateCustomerError) throw new Error("Failed to update customer.");
    if (createLeadResultError) throw new Error("Failed to create non-candidate lead result.");
  }, [createLeadResultError, updateCustomerError]);

  useEffect(() => {
    if (updateCustomerIsLoading || createLeadResultIsLoading || !disqualifyingReasons) return;
    if (createLeadResultResult) setLeadResult(createLeadResultResult);
    if (updateCustomerResult && !createLeadResultResult) setStep(Step.medicalPartOne);
  }, [
    updateCustomerIsLoading,
    createLeadResultIsLoading,
    updateCustomerResult,
    createLeadResultResult,
    disqualifyingReasons
  ]);

  return (
    <StepWrapper
      {...props}
      nextButtonLoading={
        updateCustomerIsLoading ||
        createLeadResultIsLoading ||
        !bookingController?.getTrackedCenters().length ||
        !disqualifyingReasons
      }
      onNext={onClickNext}
    >
      <QualifyStep disabled={updateCustomerIsLoading || createLeadResultIsLoading} />
    </StepWrapper>
  );
};

export default QualifyStepWrapper;
