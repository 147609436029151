import "./index.css";
import "./sentry_init";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { LocalStorageConfigs, loadFromLocal } from "./utils/LocalStorage";
import ApiRequestPaths from "./V2/Constants/ApiRequestPaths";
import { ReservationRequestWithDistance } from "./V2/Steps/Scheduling/Hooks/UseCreateOpsDistanceReservation";
import { CachedSession } from "./V2/Types/ISession";
import OpsReservation from "./V2/Types/OpsReservation";

const container = document.getElementById("root");

// Create a root.
if (!container) throw "Root could not be found.";
const root = createRoot(container);

// Initial render
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// if the user has a reservation and the environment is not local display a confirmation alert on page close
window.addEventListener("beforeunload", ev => {
  if (
    (loadFromLocal<unknown>(LocalStorageConfigs.legacyReservation.key) ||
      loadFromLocal<unknown>(LocalStorageConfigs.opsReservation.key)) &&
    process.env.REACT_APP_ENVNAME !== "Local"
  ) {
    ev.preventDefault();
    ev.returnValue = "Are you sure you want to exit? Your current reservation will be lost.";
    return "Are you sure you want to exit? Your current reservation will be lost.";
  }
});

// When closing the window if a reservation is held then release it.
window.addEventListener("unload", () => {
  const { reservation, token, leadId, customer } = new CachedSession();
  if (reservation && token && leadId && customer) {
    // Can't use useApi or axios here since no renders may occur between beforeunload being invoked and unloading.

    const requestInit: RequestInit = {
      keepalive: true,
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.token}` },
      body: JSON.stringify(new ReservationRequestWithDistance(reservation.slot, undefined, reservation.center.distance))
    };

    if (reservation instanceof OpsReservation)
      window.fetch(
        ApiRequestPaths.postOpsReservationUrlV1_1(
          leadId,
          customer?.id,
          reservation.center.id,
          reservation.service.id,
          reservation.slot.id
        ),
        requestInit
      );
    else
      window.fetch(
        `api/${ApiRequestPaths.postReleaseLegacyReservationUrl(leadId, reservation.center.id, reservation.service.id)}`,
        requestInit
      );
  }
});
