import React, { DispatchWithoutAction, useCallback, useEffect, useRef } from "react";
import { IComponentParent, TriggeredHook } from "sonobello.utilities.react";

export interface IAppErrorBoundaryProps {
  /** The flow id for the ongoing valid session, if available. */
  flowId?: string;
  /** The lead id for the ongoing valid session, if available. */
  leadId?: string;
  /** The authentication header for the request. */
  bearerToken?: string;
}

export interface IAppErrorBoundaryConfig {
  /** The fallback component to be used. */
  Fallback: React.ReactElement;
  /** The hook to trigger when the error boundary receives an error. */
  ReportSomethingWentWrong: TriggeredHook<void, Required<IAppErrorBoundaryProps>>;
  /** The error boundary component to be used. */
  ErrorBoundary: React.FC<{ fallback: React.ReactElement; onError: DispatchWithoutAction } & IComponentParent>;
}

/**
 * returns a {@link IAppErrorBoundaryConfig.ErrorBoundary} wrapping all children with error behavior set to call
 * {@link IAppErrorBoundaryConfig.ReportSomethingWentWrong} on receiving an error.
 */
const AppErrorBoundary: React.FC<IAppErrorBoundaryConfig & IAppErrorBoundaryProps & IComponentParent> = ({
  bearerToken,
  flowId,
  leadId,
  ErrorBoundary,
  Fallback,
  ReportSomethingWentWrong,
  children
}) => {
  const hookArgsRef = useRef({ flowId, leadId, bearerToken });
  useEffect(() => {
    hookArgsRef.current = { flowId, leadId, bearerToken };
  }, [flowId, leadId, bearerToken]);
  const { execute } = ReportSomethingWentWrong();
  const onError = useCallback(() => {
    if (hookArgsRef.current.flowId && hookArgsRef.current.leadId && hookArgsRef.current.bearerToken)
      execute({
        flowId: hookArgsRef.current.flowId,
        leadId: hookArgsRef.current.leadId,
        bearerToken: hookArgsRef.current.bearerToken
      });
  }, []);
  return (
    <ErrorBoundary fallback={Fallback} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
