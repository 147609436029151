import { useContext } from "react";
import { Hook } from "sonobello.utilities.react";

import AppContext from "../../../AppContext";
import ICenter from "../../Types/ICenter";
import UseGetBookingCenterOptions from "../Hooks/UseGetBookingCenterOptions";

/** A hook which obtains the booking candidate centers using the state of the session App Context. */
const AppContextUseGetBookingCenterOptions: Hook<ICenter[]> = () => {
  const { customerCState, defaultCenter } = useContext(AppContext);
  return UseGetBookingCenterOptions({
    centerId: defaultCenter.id,
    postalCode: customerCState.zipCode
  });
};

export default AppContextUseGetBookingCenterOptions;
