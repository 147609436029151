import CalendarSlotStatus from "../../Calendar/Types/CalendarSlotStatus";

export interface ILegacyCalendarTopicPayload {
  /** The id of the updated calendar. */
  readonly calendarId: string;
  /** The creation ISO timestamp of the new calendar. */
  readonly timestampUtc: string;
}

export interface IOpsCalendarTopicPayload extends ILegacyCalendarTopicPayload {
  readonly leadId: string;
  readonly customerId: string;
  readonly centerId: string;
  readonly slotId: number;
  readonly otherLeads: string[];
  readonly type: CalendarSlotStatus;
}

/** The unstable payload received from SignalR Calendar topic messages. The content should contain
 * legacy calendar properties and may optionally include ops calendar properties
 * @remarks This should be resolved into the stable {@link ICalendarTopicPayload} before being used.
 */
export type RawCalendarTopicPayload = ILegacyCalendarTopicPayload & Partial<IOpsCalendarTopicPayload>;

/** A stabilized {@link RawCalendarTopicPayload}, exactly one of its properties will have a fully validated value. */
export interface ICalendarTopicPayload {
  /** A calendar topic payload to be used in the OPS calendar process. */
  readonly ops?: IOpsCalendarTopicPayload & ILegacyCalendarTopicPayload;
  /** A calendar topic payload to be used in the legacy calendar process. */
  readonly legacy?: ILegacyCalendarTopicPayload;
}

/** {@inheritdoc} */
export class CalendarTopicPayload implements ICalendarTopicPayload {
  /** {@inheritdoc} */
  readonly ops?: IOpsCalendarTopicPayload & ILegacyCalendarTopicPayload;
  /** {@inheritdoc} */
  readonly legacy?: ILegacyCalendarTopicPayload;

  constructor(topicPayload: RawCalendarTopicPayload) {
    if (CalendarTopicPayload.resolvesToLegacy(topicPayload)) this.legacy = topicPayload;
    else this.ops = topicPayload as CalendarTopicPayload["ops"];
  }

  /** Returns a flag indicating if the param can only resolve to {@link ILegacyCalendarTopicPayload}. */
  static resolvesToLegacy = (topicPayload: RawCalendarTopicPayload): boolean =>
    !topicPayload.leadId ||
    !topicPayload.customerId ||
    !topicPayload.centerId ||
    !topicPayload.slotId ||
    !topicPayload.otherLeads ||
    topicPayload.type === undefined;
}
