import { Token } from "../../../dtos/Token";
import { LocalStorageConfigs, loadFromLocal } from "../../../utils/LocalStorage";

const censorSecretValue = (secret: string) => secret.replace(/[a-zA-Z0-9]/g, "x");

export class ErrorReport {
  error: {
    name: string;
    message: string;
    stack: string;
  };
  componentStack: string;
  localStorage: Record<string, string>;

  constructor(error: Error, componentStack: string) {
    this.error = {
      name: error.name,
      message: error.message,
      stack: error.stack || ""
    };
    this.componentStack = componentStack;
    this.localStorage = { ...localStorage };
    const token = loadFromLocal<Token>(LocalStorageConfigs.token.key);
    if (token) {
      token.token = censorSecretValue(token.token);
      token.refresh = censorSecretValue(token.refresh);
      this.localStorage[LocalStorageConfigs.token.key] = JSON.stringify(token);
    }
  }
}
