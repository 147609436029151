import { ComponentProps } from "react";
import { IComponentParent } from "sonobello.utilities.react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import ConvertExperimentContextValueProvider from "../../../Experiment/Compositions/ExperimentContextValueProvider";
import { IStepWrapperProps } from "../../Components/StepWrapper";
import { IConfirmationStepWrapperConfig, IConfirmationStepWrapperProps } from "../Components/ConfirmationStepWrapper";
import ExperimentConfirmationStepWrapper, {
  IExperimentConfirmationStepWrapperProps
} from "../Components/ExperimentConfirmationStepWrapper";

type IContextExperimentConfirmationStepWrapperProps = IAppContextConfirmationStepWrapper &
  IExperimentConfirmationStepWrapperProps &
  Pick<IConfirmationStepWrapperConfig, "router">;

type IAppContextConfirmationStepWrapper = IComponentParent &
  Pick<IStepWrapperProps, "NextButtonDesktop" | "NextButtonMobile"> &
  Pick<IConfirmationStepWrapperConfig, "router">;

/** A composed {@link ConfirmationStepWrapper} with partial props provided by the App Context. */
const AppContextExperimentConfirmationStepWrapper: React.FC<
  IConfirmationStepWrapperConfig & IContextExperimentConfirmationStepWrapperProps
> = AppContextValueProvider<
  Omit<IConfirmationStepWrapperProps, keyof IAppContextConfirmationStepWrapper | "router">,
  ComponentProps<typeof ExperimentConfirmationStepWrapper>
>({
  Component: ExperimentConfirmationStepWrapper,
  map: ({ customerCState, leadIdCState, reservation, medicalFormCState, setCustomerCState }) => {
    if (!reservation) throw "Must have a selected reservation.";
    return {
      customer: customerCState,
      leadId: leadIdCState,
      reservation,
      service: reservation.service,
      medicalForm: medicalFormCState,
      onConfirmedAppointment: customerNoteText =>
        setCustomerCState(c => ({ ...c, note: { ...c.note!, text: customerNoteText } }))
    };
  }
});

/** The {@link ExperimentConfirmationStepWrapper} with its properties provisioned by the App and Experiment contexts. */
const ContextExperimentConfirmationStepWrapper: React.FC<
  IAppContextConfirmationStepWrapper & IConfirmationStepWrapperConfig
> = ConvertExperimentContextValueProvider<
  Omit<IExperimentConfirmationStepWrapperProps, "router">,
  ComponentProps<typeof AppContextExperimentConfirmationStepWrapper>
>({
  Component: AppContextExperimentConfirmationStepWrapper,
  map: ({ recordBookedAppointment }) => ({ recordBookedAppointment })
});

export default ContextExperimentConfirmationStepWrapper;
