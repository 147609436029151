import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import React from "react";
import { ComponentProps } from "react";

import SomethingWentWrongView from "../../../components/views/SomethingWentWrongView";
import AppErrorBoundary, { IAppErrorBoundaryConfig } from "../Components/AppErrorBoundary";
import useReportSomethingWentWrong from "../Hooks/UseReportSomethingWentWrong";

/** Composes the application's outermost error boundary to use Sentry's component and fire appropriate reports when it
 * is hit with an error.
 * @remarks we force cast the SentryErrorBoundary to the {@link IAppErrorBoundaryConfig.ErrorBoundary} type to override
 * type errors. Be delicate when modifying this composition in the future.
 */
const SentryAppErrorBoundary: React.FC<
  Omit<ComponentProps<typeof AppErrorBoundary>, keyof IAppErrorBoundaryConfig>
> = ({ children, ...rest }) => {
  return (
    <AppErrorBoundary
      {...rest}
      ReportSomethingWentWrong={useReportSomethingWentWrong}
      ErrorBoundary={SentryErrorBoundary as unknown as IAppErrorBoundaryConfig["ErrorBoundary"]}
      Fallback={<SomethingWentWrongView />}
    >
      {children}
    </AppErrorBoundary>
  );
};

export default SentryAppErrorBoundary;
