import { useCallback } from "react";

import { DisqualifyingReason, DisqualifyingReasonsType } from "../../../../dtos/DisqualifyingReason";
import { LeadResult, LeadResultType } from "../../../../dtos/LeadResult";
import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import { IQualifyStepWrapperConfig, IUseCreateNonCandidateLeadResultTrigger } from "../Components/QualifyStepWrapper";

/** A hook to be used for the qualify step to create a disqualifying lead result for the session. */
const useCreateNonCandidateLeadResult: IQualifyStepWrapperConfig["UseCreateNonCandidateLeadResult"] = ({ leadId }) => {
  const { res, err, loading, setReq } = useObx<LeadResult, LeadResult>("Post LeadResult", {
    method: "post",
    url: ApiRequestPaths.postLeadResult(leadId)
  });

  const execute = useCallback(
    ({
      customerId,
      customerOpportunityId,
      disqualificationReason,
      disqualifyingReasons
    }: IUseCreateNonCandidateLeadResultTrigger) => {
      const disqualifyingReason = DisqualifyingReason.GetReason(disqualifyingReasons, disqualificationReason);
      const leadResultType =
        disqualificationReason === DisqualifyingReasonsType.ObxBmi
          ? LeadResultType.ObxNonCandidateBmi
          : LeadResultType.ObxNonCandidateMedical;
      setReq(
        r =>
          r && {
            ...r,
            payload: LeadResult.AddDisqualifyingReason(
              new LeadResult(customerId, customerOpportunityId, leadResultType),
              disqualifyingReason
            )
          }
      );
    },
    []
  );

  return { result: res?.payload || null, isLoading: loading, error: Boolean(err), execute };
};

export default useCreateNonCandidateLeadResult;
