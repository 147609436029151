import { Stack, Typography } from "@mui/material";
import React from "react";
import { IDisable } from "sonobello.utilities.react";

export interface IQualifyStepConfig {
  /** The form component which accepts user input to update qualifying information. */
  QualifyingInfoForm: React.FC<IDisable>;
}

/** The step which displays a form for the user to update their qualifying information. */
const QualifyStep: React.FC<IDisable & IQualifyStepConfig> = ({ disabled, QualifyingInfoForm }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Please answer a few medical screening questions so we may determine if you are a candidate for our procedures.
      </Typography>
      <Typography variant="body1">First, we&apos;ll need your height, weight, and date of birth:</Typography>
      <QualifyingInfoForm disabled={disabled} />
    </Stack>
  );
};

export default QualifyStep;
