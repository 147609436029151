import { useCallback } from "react";

import { Customer } from "../../../../dtos/Customer";
import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import OpsUtilities from "../../../Constants/OpsUtility";
import { IQualifyStepWrapperConfig } from "../Components/QualifyStepWrapper";

/** Hook for updating the persisted state of the customer, switching routes based on whether or not t he customer's
 * assigned center is from OPS.
 */
const useUpdateHybridCustomer: IQualifyStepWrapperConfig["UseUpdateCustomer"] = ({ leadId }) => {
  const { res, err, loading, setReq } = useObx<Customer, Customer & { bmi: number }>("Put Customer", {
    method: "put"
  });

  const execute = useCallback(
    (customerUpdate: Parameters<ReturnType<IQualifyStepWrapperConfig["UseUpdateCustomer"]>["execute"]>[0]) => {
      const bmi = Customer.getBmi(customerUpdate);
      setReq(
        r =>
          r && {
            ...r,
            url: OpsUtilities.isCenterSupported(customerUpdate.centerId)
              ? ApiRequestPaths.putOpsCustomerUrl(leadId, customerUpdate.id)
              : ApiRequestPaths.putLegacyCustomerUrl(leadId, customerUpdate.id),
            payload: { ...customerUpdate, bmi }
          }
      );
    },
    []
  );

  return { result: Boolean(res) || null, isLoading: loading, execute, error: Boolean(err) };
};

export default useUpdateHybridCustomer;
