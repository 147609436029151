import { Stack } from "@mui/material";
import React, { ReactNode, useContext } from "react";
import { useOutletContext } from "react-router";

import AppContext from "../../../AppContext";
import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import { CustomerNote } from "../../../dtos/CustomerNote";
import { IFlowStepProps } from "../../../types/Flow";
import { isMobileView } from "../../../utils/Constants";
import NeedHelpDialog from "../../NeedHelpDialog";
import NextButtonDesktop from "../../NextButtonDesktop";
import StepWrapper from "../wrapper/StepWrapper";

interface WelcomeStepWrapperProps {
  children: ReactNode;
}

const nextButtonLabel = "BOOK NOW";

/** The wrapper for all variants fo the welcome step. */
const WelcomeStepWrapper: React.FC<WelcomeStepWrapperProps> = ({ children }) => {
  const { setStep, flowStep, ...rest } = useOutletContext<IFlowStepProps>();
  const appContext = useContext(AppContext);
  const showMobile = isMobileView();

  const nextStep = flowStep.nextStep && flowStep.nextStep(appContext);
  const onClickNext =
    nextStep &&
    (() => {
      setStep(nextStep);
      appContext.setCustomerCState(c =>
        c.note
          ? c
          : {
              ...c,
              distanceToCenter:
                c.distanceToCenter ||
                EnvironmentConfiguration.optimizedPatientScheduler.fallbackCustomerDistanceToCenter,
              note: new CustomerNote({
                customer: appContext.customerCState,
                center: appContext.defaultCenter,
                disqualifyingReasons: appContext.disqualifyingReasonsCState,
                service: null
              })
            }
      );
    });
  const nextButtonLoading = !appContext.disqualifyingReasonsCState;

  return (
    <StepWrapper
      setStep={setStep}
      flowStep={flowStep}
      nextButtonLabel={nextButtonLabel}
      nextButtonRendered={showMobile ? true : false}
      nextButtonLoading={nextButtonLoading}
      {...rest}
    >
      <Stack spacing={6} sx={{ height: "100%", pt: "15%" }}>
        {children}
        {!showMobile && (
          <NextButtonDesktop
            label={nextButtonLabel}
            onClick={onClickNext}
            disabled={!onClickNext}
            isLoading={nextButtonLoading}
          />
        )}
        <NeedHelpDialog />
      </Stack>
    </StepWrapper>
  );
};

export default WelcomeStepWrapper;
